import { ReactNode, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import IconButton from '@mui/material/IconButton'
import { KeyboardDoubleArrowRight } from '@mui/icons-material'
import theme from '@/theme.ts'

interface DrawerMiniRightProps {
  tabs: {
    label: string
    icon: ReactNode
    count: number
    content: ReactNode
  }[]
}
export const DrawerMiniRight = ({ tabs }: DrawerMiniRightProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={isOpen}
      sx={{
        paddingTop: '1px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          position: 'relative',
          transition: 'width 0.2s ease',
          overflowX: 'hidden',
        },
      }}
    >
      {isOpen ? (
        <>
          <Box sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center">
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                sx={{ flex: 1 }}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    sx={{
                      fontWeight: activeTab === tabs.indexOf(tab) ? 600 : 400,
                    }}
                  />
                ))}
              </Tabs>
              <IconButton
                onClick={() => setIsOpen(false)}
                size="small"
                sx={{ ml: 2 }}
              >
                <KeyboardDoubleArrowRight />
              </IconButton>
            </Stack>
          </Box>
          <Box p={2}>{tabs[activeTab].content}</Box>
        </>
      ) : (
        <Stack spacing={4} sx={{ py: 3, px: 2 }} justifyContent="center">
          <IconButton
            key="keyCloseDrawer"
            onClick={() => setIsOpen(true)}
            sx={{ width: 40, height: 40 }}
          >
            <KeyboardDoubleArrowRight />
          </IconButton>
          {tabs.map((tab) => (
            <Box
              key={tab.label}
              sx={{
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              {tab.icon}
              {tab.count > 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: -4,
                    right: -4,
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    borderRadius: '5px',
                    width: 20,
                    height: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                  }}
                >
                  {tab.count}
                </Box>
              )}
            </Box>
          ))}
        </Stack>
      )}
    </Drawer>
  )
}
