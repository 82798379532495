import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { getDraftRFP, updateDraftRFP } from '@/api/rfps.ts'
import StepLayout, { StepConfig } from '@/components/ui/layouts/step-layout'
import BuildRFPStep from '@/components/rfps/draft/steps/build-rfp-step.tsx'
import AddVendorsStep from '@/components/rfps/draft/steps/add-vendors-step.tsx'
import ReviewAndSendStep from '@/components/rfps/draft/steps/review-and-send-step.tsx'
import { createElement, useCallback, useState, useEffect } from 'react'
import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'

export const Route = createFileRoute('/_authenticated/rfps/draft/$rfpId')({
  component: DraftRFPPage,
  validateSearch: (search: Record<string, string>) => {
    return {
      step: search.step || 'add-plants',
    }
  },
})

const RFP_STEPS: StepConfig<{
  rfpData: any
  onUpdateRfpData: (updates: Partial<any>) => void
}>[] = [
  {
    key: 'build-rfp',
    label: 'Build RFP',
    component: BuildRFPStep,
    canProgress: () => true, // TODO: Implement
  },
  {
    key: 'add-vendors',
    label: 'Add Vendors',
    component: AddVendorsStep,
    canProgress: () => true, // TODO: Implement
  },
  {
    key: 'review-and-send',
    label: 'Review & Send',
    component: ReviewAndSendStep,
  },
]

function DraftRFPPage() {
  const { rfpId } = Route.useParams()
  const { step } = Route.useSearch()
  const navigate = useNavigate({ from: Route.fullPath })
  const queryClient = useQueryClient()

  const { data: initialRfpData } = useQuery({
    queryKey: ['draft_rfp', rfpId],
    queryFn: () => getDraftRFP(rfpId),
  })

  const [rfpData, setRfpData] = useState(initialRfpData)

  useEffect(() => {
    if (initialRfpData) {
      setRfpData(initialRfpData)
    }
  }, [initialRfpData])

  const updateDraftMutation = useMutation({
    mutationFn: ({ rfpId, data }: { rfpId: string; data: any }) =>
      updateDraftRFP(rfpId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['draft_rfp', rfpId] })
    },
  })

  const handleUpdateRfpData = useCallback(
    (updates: Partial<typeof rfpData>) => {
      setRfpData((prevData: any) => ({
        ...prevData,
        ...updates,
      }))
    },
    []
  )

  const handleSaveRfpDraft = async () => {
    if (!rfpData) return

    try {
      await updateDraftMutation.mutateAsync({
        rfpId,
        data: rfpData,
      })
    } catch (error) {
      console.error('Error saving draft:', error)
    }
  }

  const currentStepComponent = RFP_STEPS.find((s) => s.key === step)?.component

  return (
    <StepLayout
      title="New RFP"
      steps={RFP_STEPS}
      currentStep={step}
      actionButtons={
        <Button
          variant="contained"
          onClick={handleSaveRfpDraft}
          disabled={updateDraftMutation.isPending}
          sx={{
            fontWeight: 400,
            padding: '10px 20px',
            borderRadius: '8px',
            border: '1px solid #C6CFD6',
            bgcolor: '#EEF2F5',
            boxShadow: 'none',
            ':hover': {
              bgcolor: '#E3E9ED',
              boxShadow: 'none',
            },
            color: theme.palette.black.main,
          }}
        >
          <Typography variant="body1">
            {updateDraftMutation.isPending ? 'Saving...' : 'Save Draft'}
          </Typography>
        </Button>
      }
      onStepChange={(newStep) =>
        navigate({
          search: { step: newStep },
          replace: true,
        })
      }
    >
      {currentStepComponent &&
        createElement(currentStepComponent, {
          rfpData,
          onUpdateRfpData: handleUpdateRfpData,
        })}
    </StepLayout>
  )
}
