import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createFileRoute } from '@tanstack/react-router'
import { ScrollText } from 'lucide-react'
import { Typography, Box, Stack } from '@mui/material'

import FlexPageLayout from '@/components/ui/layouts/page-layout-flex'
import { useCallback, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getPlantListById, updatePlantList } from '@/api/plant-list.ts'
import { PlantList } from '@/types.ts'
import { IMAGE_FILE_TYPES } from '@/constants.ts'
import FileViewerAlternative from '@/components/plant-list-details/file-viewer.tsx'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import PlantListVerificationModal from '@/components/plant-list-details/plant-list-verification-modal.tsx'
import { PlantListTableContainer } from '@/components/plant-list-details/plant-list-table-container.tsx'
import { PlantActionsHeader } from '@/components/plant-list-details/plant-list-header-section.tsx'
import { PlantActionsFooter } from '@/components/plant-list-details/plant-list-footer-section.tsx'
import ReviewAccuracyModal from '@/components/plant-list-details/plant-list-review-accuracy-modal.tsx'
import { ArchivePlantList } from '@/components/plant-list-details/plant-list-archive.tsx'

type PlantListDetailContentProps = {
  plantList: PlantList
  refetchPlantList: () => void
}

export const PlantListDetailContent = ({
  plantList,
  refetchPlantList,
}: PlantListDetailContentProps) => {
  const queryClient = useQueryClient()
  const { addToastNotification } = useToastNotifications()
  const [fileViewerFileId, setFileViewerFileId] = useState<string | null>(null)
  const [selectedFiles, setSelectedFiles] = useState(() => {
    const temp = plantList.files
      .filter(
        (file) => IMAGE_FILE_TYPES.includes(file.file_type) && !file.deleted_at
      )
      .map((file) => file.id)
    if (
      plantList.entries.some(
        (entry) => entry.file_id === null && entry.deleted_at === null
      )
    ) {
      temp.push('unassociated')
    }
    return temp
  })

  const updatePlantListMutation = useMutation({
    mutationFn: updatePlantList,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['plant-list', plantList.id],
      })
    },
  })

  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [searchFilteredPlants, setSearchFilteredPlants] = useState<Set<string>>(
    new Set(['all'])
  )
  const [verifyPlantsForTarget, setVerifyPlantsForTarget] = useState('')

  // Memoize the setSelectedPlants callback to prevent unnecessary re-renders
  const handleSetSelectedPlants = useCallback(
    (newSelectedPlants: Set<string>) => {
      setSelectedPlants(newSelectedPlants)
    },
    []
  )

  const proceedWithSendingSelectedToTarget = () => {
    addToastNotification({
      severity: 'info',
      title: 'Action not Implemented',
      message: `Sent ${selectedPlants.size} plants to ${verifyPlantsForTarget}`,
    })
  }

  const confirmSendSelectedToTarget = async () => {
    if (!plantList?.verified) {
      await updatePlantListMutation.mutateAsync({
        ...plantList,
        verified: true,
      })
    }
    proceedWithSendingSelectedToTarget()
  }

  const handleSendSelectedToTarget = (type: string) => {
    if (!plantList?.verified) {
      setVerifyPlantsForTarget(type)
    } else {
      proceedWithSendingSelectedToTarget()
    }
  }

  const availableFiles = plantList.files.filter((file) => !file.deleted_at)

  const PanelResizeHandleVertical = () => {
    return (
      <PanelResizeHandle>
        <Box
          sx={{
            width: '100px',
            height: '5px',
            backgroundColor: 'gray',
            cursor: 'col-resize',
            marginY: 1,
            borderRadius: '1em',
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      </PanelResizeHandle>
    )
  }

  const PanelResizeHandleHorizontal = () => {
    return (
      <PanelResizeHandle>
        <Box
          sx={{
            width: '5px',
            height: '100px',
            backgroundColor: 'gray',
            cursor: 'col-resize',
            marginX: 0.5,
            position: 'relative',
            borderRadius: '1em',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </PanelResizeHandle>
    )
  }

  return (
    <Stack display="flex">
      <PlantListVerificationModal
        open={!!verifyPlantsForTarget}
        onClose={() => setVerifyPlantsForTarget('')}
        onConfirm={confirmSendSelectedToTarget}
      />
      <PanelGroup direction="horizontal">
        <Panel>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              display: 'flex',
            }}
          >
            <ReviewAccuracyModal />
            <PanelGroup direction="vertical">
              <Panel>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    pt: 2,
                  }}
                >
                  <PlantActionsHeader
                    plantList={plantList}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    selectedPlants={selectedPlants}
                    setSearchFilteredPlants={setSearchFilteredPlants}
                    refetchPlantList={refetchPlantList}
                  />
                  <PlantListTableContainer
                    plantList={plantList}
                    selectedFiles={selectedFiles}
                    searchFilteredPlants={searchFilteredPlants}
                    selectedPlants={selectedPlants}
                    setSelectedPlants={handleSetSelectedPlants}
                    setTargetFileId={setFileViewerFileId}
                  />
                </Box>
              </Panel>
              <PanelResizeHandleVertical />
              <Panel>
                <PlantActionsFooter
                  selectedPlants={selectedPlants}
                  handleSendSelectedToTarget={handleSendSelectedToTarget}
                />
              </Panel>
            </PanelGroup>
          </Box>
        </Panel>
        <PanelResizeHandleHorizontal />
        <Panel>
          <Box>
            <FileViewerAlternative
              files={availableFiles}
              onFileDelete={refetchPlantList}
              fileId={fileViewerFileId}
              onFileIdChange={setFileViewerFileId}
            />
          </Box>
        </Panel>
      </PanelGroup>
    </Stack>
  )
}

export const Route = createFileRoute('/_authenticated/plants/$plantListId')({
  component: PlantListDetail,
})

function PlantListDetail() {
  const { plantListId } = Route.useParams()
  const {
    error,
    data: plantList,
    isFetching,
    refetch: refetchPlantList,
  } = useQuery({
    queryKey: ['plant-list', plantListId] as const,
    queryFn: ({ queryKey }) => {
      const [_, plantListId] = queryKey
      return getPlantListById(plantListId as string)
    },
    refetchOnWindowFocus: false,
  })

  return (
    <FlexPageLayout
      title={plantList?.name || 'n/a'}
      titleBarElement={<ArchivePlantList plantList={plantList as PlantList} />}
      icon={<ScrollText size={36} />}
      backRoute={{ to: '/plants', label: 'All Plant Lists' }}
    >
      {isFetching ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography>Error: {error.message}</Typography>
      ) : (
        <PlantListDetailContent
          plantList={plantList as PlantList}
          refetchPlantList={refetchPlantList}
        />
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </FlexPageLayout>
  )
}
