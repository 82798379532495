import { MessageSquareTextIcon, NotepadText, History } from 'lucide-react'
import { DrawerMiniRight } from '@/components/ui/base/drawer-mini-right.tsx'
import { RFPComments } from '@/components/rfps/draft/drawer/rfp-drawer-comments.tsx'
import { RFPNotes } from '@/components/rfps/draft/drawer/rfp-drawer-notes.tsx'
import { RFPHistory } from '@/components/rfps/draft/drawer/rfp-drawer-history.tsx'

export const RFPDrawer = () => {
  const drawerItems = [
    {
      label: 'Comments',
      icon: <MessageSquareTextIcon />,
      content: <RFPComments />,
      count: 0,
    },
    {
      label: 'Notes',
      icon: <NotepadText />,
      content: <RFPNotes />,
      count: 1,
    },
    {
      label: 'History',
      icon: <History />,
      content: <RFPHistory />,
      count: 0,
    },
  ]

  return <DrawerMiniRight tabs={drawerItems} />
}
