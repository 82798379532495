import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'
import Button from '@mui/material/Button'
import { Plus } from 'lucide-react'
import { Stack } from '@mui/material'
import RfpPlantTable from '@/components/rfps/rfp-plant-table.tsx'
import { PlantListEntry, PlantWithQuantity } from '@/types.ts'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { useEffect, useState } from 'react'
import AddPlantsModal from '@/components/ui/modals/add-plants-modal.tsx'
import { generateObjectId } from '@/lib/utils.ts'
import { DEFAULT_PLANT_LIST_ENTRY } from '@/constants.ts'
import { RFPDrawer } from '@/components/rfps/draft/drawer/rfp-drawer-right.tsx'

interface BuildRFPStepProps {
  rfpData: any
  onUpdateRfpData: (updates: Partial<any>) => void
}

export default function BuildRFPStep({
  rfpData,
  onUpdateRfpData,
}: BuildRFPStepProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [addPlantsModalOpen, setAddPlantsModalOpen] = useState(false)
  const [rfpPlants, setRfpPlants] = useState<PlantListEntry[]>(
    rfpData?.plants || []
  )
  const open = Boolean(anchorEl)

  useEffect(() => {
    setRfpPlants(rfpData?.plants || [])
  }, [rfpData?.plants])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddPlants = async (plants: PlantWithQuantity[]) => {
    const updatedPlantEntries = [...rfpPlants]

    for (const plant of plants) {
      const newId = generateObjectId()
      const newPlant: PlantListEntry = {
        ...DEFAULT_PLANT_LIST_ENTRY,
        id: newId,
        is_new: true,
        quantity_count: { min: plant.quantity, max: null },
        scientific_name: plant.plant.scientific_name,
        common_name: plant.plant.common_names[0],
        parent_of_order: null,
      }

      // find the entry with null parent_of_order in current list
      const currentLastEntry = updatedPlantEntries.find(
        (entry) => entry.parent_of_order === null
      )
      if (currentLastEntry) {
        currentLastEntry.parent_of_order = newId
      }
      updatedPlantEntries.push(newPlant)
    }

    setRfpPlants(updatedPlantEntries)
    setAddPlantsModalOpen(false)
    handleClose()

    onUpdateRfpData({ plants: updatedPlantEntries })
  }

  const handlePlantsUpdate = (updatedPlants: PlantListEntry[]) => {
    setRfpPlants(updatedPlants)
    onUpdateRfpData({ plants: updatedPlants })
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ flexGrow: 1, p: 3.75 }}>
        <Box sx={{ mb: 2.5 }}>
          <Typography variant="h2" fontWeight={400}>
            Add Plants &amp; RFP Details
          </Typography>
        </Box>
        <Stack
          spacing={3}
          sx={{
            borderRadius: theme.borderRadius.lg,
            bgcolor: 'white',
            p: 5.5,
          }}
        >
          <Box>
            <Typography variant="h2">TODO: Organization Card</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Box sx={{ width: '50%' }}>
              <Typography variant="h3">TODO: RFP Details</Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography variant="h3">TODO: Job Details</Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 1.25,
                pr: 0,
              }}
            >
              <Typography variant="h3">Request Pricing for Plants</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="body1">TODO: Bulk Actions</Typography>
                <Button variant="contained" onClick={handleClick}>
                  <Plus size={18} />
                  <Typography variant="button">Add Plants</Typography>
                </Button>
              </Box>
            </Box>
            <RfpPlantTable plants={rfpPlants} onUpdate={handlePlantsUpdate} />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => setAddPlantsModalOpen(true)}>
                Add Manually
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                From Existing Plant List...
              </MenuItem>
              <MenuItem onClick={handleClose}>From File Upload...</MenuItem>
            </Menu>
          </Box>
        </Stack>
        <AddPlantsModal
          open={addPlantsModalOpen}
          onClose={() => setAddPlantsModalOpen(false)}
          onAddPlants={handleAddPlants}
        />
      </Box>
      <RFPDrawer />
    </Box>
  )
}
